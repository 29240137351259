<template lang='pug'>
    #productLineItem.page-noScroll.main-page.page-noScroll.position-relative(style='background-color:#ffffff;')
        v-overlay(v-show='confirm_loading' opacity='0' z-index='999')
        div(v-if='target_class == "oppty"')
            .button-section 
                v-row
                    v-col(:cols='"4"')
                        p.d-flex.align-center.input-has-label
                            label {{$t('PRODUCTLINEITEM.TOTAL_PRODUCTS')}}
                            v-text-field.ellipsis(v-model='productList.length' solo readonly class='num_field') 
                    v-col(:cols='"4"')
                        p.d-flex.align-center.input-has-label
                            label {{$t('PRODUCTLINEITEM.QUANTITY')}}
                            v-text-field.ellipsis(v-model='productsData.total_quantity' solo readonly class='num_field')
                    v-col(:cols='"4"')
                        v-menu(offset-y left :attach='false')
                            template(v-slot:activator="{ on, attrs }")
                                p.d-flex.align-center.input-has-label
                                    label {{$t('PRODUCTLINEITEM.TOTAL')}}
                                    v-text-field.ellipsis(v-model='total' solo readonly class='num_field')
                                        template(v-slot:append)
                                            v-btn( icon v-bind="attrs" v-on="on")
                                                v-icon(size='24') mdi-unfold-more-horizontal
                            v-list
                                v-list-item
                                    v-list-item-title {{$t('PRODUCTLINEITEM.SUBTOTAL_LIST_PRICE')}}
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.subtotal_list_price )}}
                                v-list-item(v-for='item, index in productsData.other_charges_data' style='min-height: 25px !important;' :key='`otherc_harges-${index}`')
                                    v-list-item-subtitle.d-flex
                                        span {{$t('PRODUCTLINEITEM.OC')}}
                                        span {{ `(${item.other_charges}%)` }}
                                    v-list-item-subtitle(class='text-end') {{getFormatAmount( item.other_charges_amount )}}
                                v-list-item
                                    v-list-item-title(:title='getFormatAmount( productsData.total_discount_rate)+"%"') {{$t('PRODUCTLINEITEM.OC_DISCOUNT')}} ({{getFormatAmount( productsData.total_other_charges_rate)}}%)
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.total_other_charges )}}
                                v-list-item(v-for='item, index in productsData.discount_data' style='min-height: 25px !important;' :key='`discount-${index}`')
                                    v-list-item-subtitle.d-flex
                                        span {{$t('PRODUCTLINEITEM.DISCOUNT_DATA')}}
                                        span {{ `(${item.discount}%)` }}
                                    v-list-item-subtitle(class='text-end') {{getFormatAmount( item.discount_amount )}}
                                v-list-item
                                    v-list-item-title(:title='getFormatAmount( productsData.total_discount_rate)+"%"') {{$t('PRODUCTLINEITEM.TOTAL_DISCOUNT')}} ({{getFormatAmount( productsData.total_discount_rate)}}%)
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.total_discount )}}
                                v-list-item
                                    v-list-item-title {{$t('PRODUCTLINEITEM.SUBTOTAL_AMOUNT')}}
                                    v-list-item-title(class='text-end') {{getFormatAmount( productsData.subtotal_amount )}}
                                div(v-if='tax_option == "TAX_EXCLUSIVE" && tax_data != null')
                                    v-list-item(v-for='item, index in tax_data.TAX_EXCLUSIVE' style='min-height: 25px !important;' :key='`tax-${index}`')
                                        v-list-item-subtitle.d-flex
                                            span {{$t('PRODUCTLINEITEM.TAX_DATA')}}
                                            span {{ `(${item.tax}%)` }}
                                        v-list-item-subtitle(class='text-end') {{getFormatAmount( item.tax_amount )}}
                                v-list-item(v-if='tax_option == "TAX_EXCLUSIVE" && sales_tax != null')
                                    v-list-item-title {{$t('PRODUCTLINEITEM.SALES_TAX')}}
                                    v-list-item-title(v-if='tax_option == "TAX_EXCLUSIVE"' class='text-end') {{getFormatAmount( sales_tax.TAX_EXCLUSIVE )}}
                                    v-list-item-title(v-else class='text-end') {{getFormatAmount( sales_tax.TAX_EXCLUSIVE )}}
                                v-divider(class='my-1')
                                v-list-item
                                    v-list-item-title {{$t('PRODUCTLINEITEM.TOTAL')}}
                                    v-list-item-title(class='text-end') {{ total }}
        #page-inner.scroll-x.pb-0.height_100vh
            .data-area
                v-form(style='position: relative;' ref='productLineItem_form')
                    v-data-table.table_in_product_tab(v-if='service_or_license == "service"' :headers='headers' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" v-sortable-data-table item-key='id' v-model="selectedRows" :items='productList' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort hide-default-footer='hide-default-footer' @sorted='get_sequence($event)' :loading='loading_data' loading-text='loading...' :style='' fixed-header)
                        template(v-slot:item.product_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-if='!showCombobox[item.sequence - 1] || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.product_name' @focus='nameBlur(item)' :rules='formRules.nameRules' :title='item.product_name')
                                        template(v-slot:append-outer)
                                            .mr-1(v-if="isNew[item.sequence]")
                                                v-icon(color="red", size="25") mdi-new-box
                                    v-combobox(v-else-if='showCombobox[item.sequence - 1] && !isTechUser && is_staff' v-model="combo_product" :items="productSearchList" item-text="name" item-value="id" :search-input.sync="search" :loading="comboBoxLoading" @keydown.tab='nameTabBlur(index,item)' @blur='nameComboBlur(item)' ref='combobox' :rules='formRules.nameRules')
                                        template(v-slot:item="data")
                                            div.ellipsis(v-if="data.item.id == 0")
                                                span {{ data.item.name }}
                                                v-icon.mr-1(color="red", size="25") mdi-new-box
                                            div.ellipsis(v-else)
                                                span {{ data.item.name }}
                        template(v-slot:item.organization='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.organization' :title='item.organization' readonly)
                        template(v-slot:item.price='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.price' :readonly='isTechUser || !is_staff' :title='item.price' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field', :ref='"price_"+index')
                        template(v-slot:item.quantity='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.quantity' :readonly='isTechUser || service_or_license == "service" || !is_staff' :title='item.quantity' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field')
                        
                        template(v-slot:item.start_date='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field.v-text-field_low_z-index(v-model='item.start_date' :readonly='isTechUser || !is_staff' :title='item.start_date'  v-bind="attrs" v-on="on"  hide-details="auto"  :rules='formRules.fromToRule' class='num_field' :clearable='!isTechUser && is_staff' :change='toDateCheck(item)' )
                                        v-date-picker(v-if='!isTechUser && is_staff' v-model='item.start_date' @input='menu_from = false' no-title scrollable) 
                        template(v-slot:item.end_date='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field.v-text-field_low_z-index(v-model='item.end_date' :readonly='isTechUser || !is_staff' :title='item.end_date'  v-bind="attrs" v-on="on" hide-details="auto" :rules='formRules.fromToRule' class='num_field' :clearable='!isTechUser && is_staff'  :input='calcPriceAndQuantity(item)')
                                        v-date-picker(v-if='!isTechUser && is_staff' v-model='item.end_date' @input='menu_to = false' no-title scrollable :min='minDate(item.start_date)') 
                        template(v-slot:item.unit='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.unit' :readonly='isTechUser || !is_staff' :title='item.unit')
                        template(v-slot:item.other_charges='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.other_charges' :readonly='isTechUser || !is_staff' :title='item.other_charges' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field')
                        template(v-slot:item.tax='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.tax' :readonly='isTechUser || !is_staff' :title='item.tax' type='number' :rules='formRules.numberRule' class='num_field')
                        template(v-slot:item.discount='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.discount' :readonly='isTechUser || !is_staff' :title='item.discount' type='number' :rules='formRules.numberRule' @input='calcDiscount(item)' class='num_field')
                        template(v-slot:item.net_amount='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.net_amount' :title='item.net_amount' type='number' :rules='formRules.numberRule' @input='calcAmount(item)' :readonly='item.price == 0 || item.quantity == 0 ? true : false || isTechUser || !is_staff' class='num_field')
                        template(v-slot:item.active='{ item }')
                            v-btn(v-if='target_class != "oppty"' :disabled='!can_edit || isTechUser || !is_staff' fab width='20' height='20' x-small @click='editSo(item)' color='primary' depressed dark) SO
                            v-btn(:disabled='!can_edit || isTechUser || !is_staff' icon='icon' @click='onDelete(item)')
                                v-icon(size='18') icon-remove
                        //- ------------------------------------------------
                        template(v-slot:item.owner_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.owner",
                                        :items="user_list",
                                        item-text="fullName",
                                        item-value="id",
                                        flat,
                                        :title='user_list.filter(el=> el.id == item.owner )[0]?.fullName'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.order_status='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.order_status",
                                        :items="order_status_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(order_status_list,item.order_status)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.remain_days='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-if='parseInt(item.order_status)>4' :value='getItemNameFromList(order_status_list,item.order_status)' :title='getItemNameFromList(order_status_list,item.order_status)' readonly class='num_field')
                                    v-text-field.v-text-field_low_z-index(v-else v-model='item.remain_days' readonly :title='item.remain_days' class='num_field')
                        template(v-slot:item.end_account_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.end_account",
                                        :items="end_account_list",
                                        item-text="name",
                                        item-value="id",
                                        flat,
                                        :title='getItemNameFromListById(end_account_list,item.end_account)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.contact_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`contact_${index}`' autofocus v-show='!loading_data && $refs[`contact_${index}`].isFocused && !isTechUser && is_staff' v-model="item.contact" :items="contact_list" @blur='contactBlur(item)' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" :rules='formRules.contactRules' :loading='contact_loading')
                                        template(v-slot:item='data')
                                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                v-icon(v-if="data.item.image == null") mdi-account
                                                img(v-else :src='data.item.image')
                                            .t-black {{ data.item.full_name }} 
                                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`contact_${index}`].isFocused || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.contact_name' @click='contactFocus(index)' :title='item.contact_name')
                        

                        template(v-slot:item.contact_second_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`contact_sec_${index}`' autofocus v-show='!loading_data && $refs[`contact_sec_${index}`].isFocused && !isTechUser && is_staff' v-model="item.contact_second" :items="contact_list" @blur='contactBlur(item)' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" :rules='formRules.contactRules' :loading='contact_loading')
                                        template(v-slot:item='data')
                                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                v-icon(v-if="data.item.image == null") mdi-account
                                                img(v-else :src='data.item.image')
                                            .t-black {{ data.item.full_name }} 
                                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`contact_sec_${index}`].isFocused || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.contact_second_name' @click='contactFocus2(index)' :title='item.contact_second_name')

                        template(v-slot:item.contact_third_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`contact_rd_${index}`' autofocus v-show='!loading_data && $refs[`contact_rd_${index}`].isFocused && !isTechUser && is_staff' v-model="item.contact_third" :items="contact_list" @blur='contactBlur(item)' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" :rules='formRules.contactRules' :loading='contact_loading')
                                        template(v-slot:item='data')
                                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                v-icon(v-if="data.item.image == null") mdi-account
                                                img(v-else :src='data.item.image')
                                            .t-black {{ data.item.full_name }} 
                                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`contact_rd_${index}`].isFocused || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.contact_third_name' @click='contactFocus3(index)' :title='item.contact_third_name')

                        template(v-slot:item.product_type='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.type",
                                        :items="product_type_list",
                                        item-text="name",
                                        item-value="id",
                                        flat,
                                        :title='getItemNameFromListById(product_type_list,item.type)'
                                        :disabled='isTechUser || !is_staff'
                                        :rules='formRules.requireRule'
                                    )
                        template(v-slot:item.project_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.project_name' :readonly='isTechUser || !is_staff' :title='item.project_name' :rules='formRules.contentLenRule200')
                        template(v-slot:item.sla='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.sla",
                                        :items="sla_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(sla_list,item.sla)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.service_content='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.service_content' :title='item.service_content' readonly @click='editText(item,"service_content")')
                        template(v-slot:item.service_plan='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.service_plan",
                                        :items="service_plan_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(service_plan_list,item.service_plan)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.service_vendor='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.service_vendor",
                                        :items="service_vendor_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(service_vendor_list,item.service_vendor)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.order_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.order_number' :readonly='isTechUser || !is_staff' :title='item.order_number' :rules='formRules.contentLenRule50')
                        template(v-slot:item.status='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.status",
                                        :items="status_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(status_list,item.status)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.note='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.note' :title='item.note' readonly @click='editText(item,"note")')
                        template(v-slot:item.sales_order='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    a.text-primary(@click="soOpen(item.sales_order.name)")
                                        v-text-field.v-text-field_low_z-index(v-model='item.sales_order.name' :title='item.sales_order.name' :readonly='true')
                        template(v-slot:item.sales_order_line='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.sales_order_line' :title='item.sales_order_line' :readonly='true' :rules='formRules.contentLenRule250')
                        template(v-slot:item.purchase_order='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    a.text-primary(@click="poOpen(item.purchase_order)")
                                        v-text-field.v-text-field_low_z-index(v-model='item.purchase_order' :title='item.purchase_order' :readonly='true' :rules='formRules.contentLenRule250')
                        template(v-slot:item.project_code='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.project_code' :readonly='isTechUser || !is_staff' :title='item.project_code' :rules='formRules.contentLenRule50')
                        template(v-slot:item.related_opportunity='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`oppty_${index}`' autofocus v-show='!loading_data && $refs[`oppty_${index}`].isFocused && !isTechUser && is_staff' v-model="item.related_opportunity" :items="oppty_list" @blur='opptyBlur(item)' :search-input.sync="opptySearch" :menu-props="{openOnClick: true}" item-value="id" item-text="name" hide-details="auto" :loading='oppty_loading')
                                        template(v-slot:item='data')
                                            .t-black.ellipsis {{ data.item.name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.name') {{ data.item.name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`oppty_${index}`].isFocused || isTechUser' :readonly='isTechUser || !is_staff' v-model='item.related_opportunity_name' @click='opptyFocus(index)' :title='item.related_opportunity_name')
                                    //- v-combobox(v-model="item.related_opportunity" :items="oppty_list" @blur='oppty_list = []' :search-input.sync="opptySearch"  item-text="name" item-value="id" hide-details="auto" :loading='oppty_loading')
                        template(v-slot:item.technician_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.technician",
                                        :items="technician_list",
                                        item-text="fullName",
                                        item-value="id",
                                        flat,
                                        :title='technician_list.filter(el=> el.id == item.technician )[0]?.fullName'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.technician_cost='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.technician_cost' :readonly='isTechUser || !is_staff'  :title='item.technician_cost' class='num_field')
                        template(v-slot:item.invoice_month='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-if='target_class != "oppty"'
                                        v-model="item.invoice_month",
                                        :items="invoice_month_list",
                                        item-text="key",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(invoice_month_list,item.invoice_month)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                                    v-menu(v-else :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field.v-text-field_low_z-index(v-model='item.invoice_month' :readonly='isTechUser || !is_staff' :title='item.invoice_month'  v-bind="attrs" v-on="on"  hide-details="auto" class='num_field' :clearable='!isTechUser && is_staff' :change='toDateCheck(item)' )
                                        v-date-picker(v-if='!isTechUser && is_staff' v-model='item.invoice_month' @input='menu_from = false' no-title scrollable) 

                        template(v-slot:item.invoice_price='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.invoice_price' :readonly='isTechUser || !is_staff' :title='item.invoice_price' class='num_field' :rules='formRules.numberRule')
                        template(v-slot:item.estimated_gross_profit='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.estimated_gross_profit'  :readonly='isTechUser || !is_staff' :title='item.estimated_gross_profit' class='num_field' :rules='formRules.negativeNumberRule')
                        template(v-slot:item.profit='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.profit' :readonly='isTechUser || !is_staff'  :title='item.profit' class='num_field' :rules='formRules.negativeNumberRule')
                    v-data-table.table_in_product_tab(v-if='service_or_license == "license"' :headers='headers' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" v-sortable-data-table item-key='id' v-model="selectedRows" :items='productList' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort hide-default-footer='hide-default-footer' @sorted='get_sequence($event)' :loading='loading_data' loading-text='loading...' :style='' fixed-header)
                        template(v-slot:item.product_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-if='!showCombobox[item.sequence - 1] || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.product_name' @focus='nameBlur(item)' :rules='formRules.nameRules' :title='item.product_name')
                                        template(v-slot:append-outer)
                                            .mr-1(v-if="isNew[item.sequence]")
                                                v-icon(color="red", size="25") mdi-new-box
                                    v-combobox(v-else-if='showCombobox[item.sequence - 1] && !isTechUser && is_staff' v-model="combo_product" :items="productSearchList" item-text="name" item-value="id" :search-input.sync="search" :loading="comboBoxLoading" @keydown.tab='nameTabBlur(index,item)' @blur='nameComboBlur(item)' ref='combobox' :rules='formRules.nameRules')
                                        template(v-slot:item="data")
                                            div.ellipsis(v-if="data.item.id == 0")
                                                span {{ data.item.name }}
                                                v-icon.mr-1(color="red", size="25") mdi-new-box
                                            div.ellipsis(v-else)
                                                span {{ data.item.name }}
                        template(v-slot:item.organization='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.organization' :title='item.organization' readonly)
                        template(v-slot:item.price='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.price' :readonly='isTechUser || !is_staff' :title='item.price' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field', :ref='"price_"+index')
                        template(v-slot:item.quantity='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.quantity' :readonly='isTechUser || !is_staff' :title='item.quantity' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field')
                        
                        template(v-slot:item.start_date='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field.v-text-field_low_z-index(v-model='item.start_date' :readonly='isTechUser || !is_staff' :title='item.start_date'  v-bind="attrs" v-on="on"  hide-details="auto"  :rules='formRules.fromToRule' class='num_field' :clearable='!isTechUser && is_staff' :change='toDateCheck(item)' )
                                        v-date-picker(v-if='!isTechUser && is_staff' v-model='item.start_date' @input='menu_from = false' no-title scrollable) 
                        template(v-slot:item.end_date='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field.v-text-field_low_z-index(v-model='item.end_date' :readonly='isTechUser || !is_staff' :title='item.end_date'  v-bind="attrs" v-on="on" hide-details="auto" :rules='formRules.fromToRule'  class='num_field' :clearable='!isTechUser && is_staff'  :input='calcPriceAndQuantity(item)')
                                        v-date-picker(v-if='!isTechUser && is_staff' v-model='item.end_date' @input='menu_to = false' no-title scrollable :min='minDate(item.start_date)') 
                        template(v-slot:item.product_code='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.product_code' :readonly='isTechUser || !is_staff' :title='item.product_code' :rules='formRules.contentLenRule50')
                        template(v-slot:item.other_charges='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.other_charges' :readonly='isTechUser || !is_staff' :title='item.other_charges' type='number' :rules='formRules.numberRule' :input='calcPriceAndQuantity(item)' class='num_field')
                        template(v-slot:item.tax='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.tax' :readonly='isTechUser || !is_staff' :title='item.tax' type='number' :rules='formRules.numberRule' class='num_field')
                        template(v-slot:item.discount='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.discount' :readonly='isTechUser || !is_staff' :title='item.discount' type='number' :rules='formRules.numberRule' @input='calcDiscount(item)' class='num_field')
                        template(v-slot:item.net_amount='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model.number='item.net_amount' :title='item.net_amount' type='number' :rules='formRules.numberRule' @input='calcAmount(item)' :readonly='item.price == 0 || item.quantity == 0 ? true : false || isTechUser || !is_staff' class='num_field')
                        template(v-slot:item.active='{ item }')
                            v-btn(v-if='target_class != "oppty"' :disabled='!can_edit || isTechUser || !is_staff' fab width='20' height='20' x-small @click='editSo(item)' color='primary' depressed dark) SO
                            v-btn(:disabled='!can_edit || isTechUser || !is_staff' icon='icon' @click='onDelete(item)')
                                v-icon(size='18') icon-remove
                        //- ------------------------------------------------
                        template(v-slot:item.owner_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.owner",
                                        :items="user_list",
                                        item-text="fullName",
                                        item-value="id",
                                        flat,
                                        :title='user_list.filter(el=> el.id == item.owner )[0]?.fullName'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.order_status='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.order_status",
                                        :items="order_status_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(order_status_list,item.order_status)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.remain_days='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-if='parseInt(item.order_status)>4' :value='getItemNameFromList(order_status_list,item.order_status)' :title='getItemNameFromList(order_status_list,item.order_status)' readonly class='num_field')
                                    v-text-field.v-text-field_low_z-index(v-else v-model='item.remain_days' readonly :title='item.remain_days' class='num_field')
                        template(v-slot:item.end_account_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.end_account",
                                        :items="end_account_list",
                                        item-text="name",
                                        item-value="id",
                                        flat,
                                        :title='getItemNameFromListById(end_account_list,item.end_account)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.contact_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`contact_${index}`' autofocus v-show='!loading_data && $refs[`contact_${index}`].isFocused && !isTechUser && is_staff' v-model="item.contact" :items="contact_list" @blur='contactBlur(item)' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" :rules='formRules.contactRules' :loading='contact_loading')
                                        template(v-slot:item='data')
                                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                v-icon(v-if="data.item.image == null") mdi-account
                                                img(v-else :src='data.item.image')
                                            .t-black {{ data.item.full_name }} 
                                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`contact_${index}`].isFocused || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.contact_name' @click='contactFocus(index)' :title='item.contact_name')
                        
                        template(v-slot:item.contact_second_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`contact_sec_${index}`' autofocus v-show='!loading_data && $refs[`contact_sec_${index}`].isFocused && !isTechUser && is_staff' v-model="item.contact_second" :items="contact_list" @blur='contactBlur(item)' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" :rules='formRules.contactRules' :loading='contact_loading')
                                        template(v-slot:item='data')
                                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                v-icon(v-if="data.item.image == null") mdi-account
                                                img(v-else :src='data.item.image')
                                            .t-black {{ data.item.full_name }} 
                                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`contact_sec_${index}`].isFocused || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.contact_second_name' @click='contactFocus2(index)' :title='item.contact_second_name')

                        template(v-slot:item.contact_third_name='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`contact_rd_${index}`' autofocus v-show='!loading_data && $refs[`contact_rd_${index}`].isFocused && !isTechUser && is_staff' v-model="item.contact_third" :items="contact_list" @blur='contactBlur(item)' :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_CASE.NO_CONTACT')` item-text="searchStr" item-value="id" hide-details="auto" :rules='formRules.contactRules' :loading='contact_loading')
                                        template(v-slot:item='data')
                                            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                                v-icon(v-if="data.item.image == null") mdi-account
                                                img(v-else :src='data.item.image')
                                            .t-black {{ data.item.full_name }} 
                                            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`contact_rd_${index}`].isFocused || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.contact_third_name' @click='contactFocus3(index)' :title='item.contact_third_name')


                        template(v-slot:item.product_type='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.type",
                                        :items="product_type_list",
                                        item-text="name",
                                        item-value="id",
                                        flat,
                                        :title='getItemNameFromListById(product_type_list,item.type)'
                                        :disabled='isTechUser || !is_staff'
                                        :rules='formRules.requireRule'
                                    )
                        template(v-slot:item.project_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.project_name' :readonly='isTechUser || !is_staff' :title='item.project_name' :rules='formRules.contentLenRule200')
                        template(v-slot:item.billing_frequency='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.billing_frequency",
                                        :items="billing_frequency_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(billing_frequency_list,item.billing_frequency)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.order_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.order_number' :readonly='isTechUser || !is_staff' :title='item.order_number' :rules='formRules.contentLenRule50')
                        template(v-slot:item.status='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.status",
                                        :items="status_list",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(status_list,item.status)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.note='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.note' :title='item.note' readonly @click='editText(item,"note")')
                        template(v-slot:item.sales_order='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    a.text-primary(@click="soOpen(item.sales_order.name)")
                                        v-text-field.v-text-field_low_z-index(v-model='item.sales_order.name' :title='item.sales_order.name' :readonly='true')
                        template(v-slot:item.sales_order_line='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.sales_order_line' :title='item.sales_order_line' :readonly='true' :rules='formRules.contentLenRule250')
                        template(v-slot:item.purchase_order='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    a.text-primary(@click="poOpen(item.purchase_order)")
                                        v-text-field.v-text-field_low_z-index(v-model='item.purchase_order' :title='item.purchase_order' :readonly='true' :rules='formRules.contentLenRule250')
                        template(v-slot:item.project_code='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.project_code' :readonly='isTechUser || !is_staff' :title='item.project_code' :rules='formRules.contentLenRule50')
                        template(v-slot:item.related_opportunity='{ item,index }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-combobox(:ref='`oppty_${index}`' autofocus v-show='!loading_data && $refs[`oppty_${index}`].isFocused && !isTechUser && is_staff' v-model="item.related_opportunity" :items="oppty_list" @blur='opptyBlur(item)' :search-input.sync="opptySearch" :menu-props="{openOnClick: true}" item-value="id" item-text="name" hide-details="auto" :loading='oppty_loading')
                                        template(v-slot:item='data')
                                            .t-black.ellipsis {{ data.item.name }}
                                        template(v-slot:selection='data')
                                            .t-black.ellipsis(:title='data.item.name') {{ data.item.name }}
                                    v-text-field.v-text-field_low_z-index(v-show='!loading_data && !$refs[`oppty_${index}`].isFocused || isTechUser || !is_staff' :readonly='isTechUser || !is_staff' v-model='item.related_opportunity_name' @click='opptyFocus(index)' :title='item.related_opportunity_name')
                                    //- v-combobox(v-model="item.related_opportunity" :items="oppty_list" @blur='oppty_list = []' :search-input.sync="opptySearch"  item-text="name" item-value="id" hide-details="auto" :loading='oppty_loading')
                        template(v-slot:item.technician_name='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.technician",
                                        :items="technician_list",
                                        item-text="fullName",
                                        item-value="id",
                                        flat,
                                        :title='technician_list.filter(el=> el.id == item.technician )[0]?.fullName'
                                        :disabled='isTechUser || !is_staff'
                                    )
                        template(v-slot:item.technician_cost='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.technician_cost' :readonly='isTechUser || !is_staff'  :title='item.technician_cost' class='num_field')
                        template(v-slot:item.invoice_month='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-if='target_class != "oppty"'
                                        v-model="item.invoice_month",
                                        :items="invoice_month_list",
                                        item-text="key",
                                        item-value="key",
                                        flat,
                                        :title='getItemNameFromList(invoice_month_list,item.invoice_month)'
                                        :disabled='isTechUser || !is_staff'
                                    )
                                    v-menu(v-else :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                        template(v-slot:activator="{ on, attrs }")
                                            v-text-field.v-text-field_low_z-index(v-model='item.invoice_month' :readonly='isTechUser || !is_staff' :title='item.invoice_month'  v-bind="attrs" v-on="on"  hide-details="auto" class='num_field' :clearable='!isTechUser && is_staff' :change='toDateCheck(item)' )
                                        v-date-picker(v-if='!isTechUser && is_staff' v-model='item.invoice_month' @input='menu_from = false' no-title scrollable) 
                        template(v-slot:item.invoice_price='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.invoice_price' :readonly='isTechUser || !is_staff'  :title='item.invoice_price' class='num_field' :rules='formRules.numberRule')
                        template(v-slot:item.estimated_gross_profit='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.estimated_gross_profit' :readonly='isTechUser || !is_staff'  :title='item.estimated_gross_profit' class='num_field' :rules='formRules.negativeNumberRule')
                        template(v-slot:item.profit='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.profit' :readonly='isTechUser || !is_staff' :title='item.profit' class='num_field' :rules='formRules.negativeNumberRule')
                        //- new 9 field
                        template(v-slot:item.subcription_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.subcription_number' :class='!checkFieldEditable(item.type,"subcription_number")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"subcription_number") || isTechUser || !is_staff' :title='item.subcription_number' :rules='formRules.contentLenRule100')
                        template(v-slot:item.agreement_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.agreement_number' :class='!checkFieldEditable(item.type,"agreement_number")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"agreement_number") || isTechUser' :title='item.agreement_number' :rules='formRules.contentLenRule100')
                        template(v-slot:item.contract_level='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-autocomplete(
                                        v-model="item.contract_level",
                                        :class='!checkFieldEditable(item.type,"contract_level")? "blue lighten-5":""',
                                        :disabled='checkFieldEditable(item.type,"contract_level") || isTechUser'
                                        :items="getContractLevelList(item.type)",
                                        item-text="name",
                                        item-value="key",
                                        flat,
                                        :title='item.contract_level'
                                    )
                        template(v-slot:item.contract_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.contract_number' :class='!checkFieldEditable(item.type,"contract_number")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"contract_number") || isTechUser' :title='item.contract_number' :rules='formRules.contentLenRule100')
                        template(v-slot:item.pa_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.pa_number' :class='!checkFieldEditable(item.type,"pa_number")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"pa_number") || isTechUser' :title='item.pa_number' :rules='formRules.contentLenRule100')
                        template(v-slot:item.oks_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.oks_number' :class='!checkFieldEditable(item.type,"oks_number")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"oks_number") || isTechUser' :title='item.oks_number' :rules='formRules.contentLenRule100')
                        template(v-slot:item.csi_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.csi_number' :class='!checkFieldEditable(item.type,"csi_number")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"csi_number") || isTechUser' :title='item.csi_number' :rules='formRules.contentLenRule100')
                        template(v-slot:item.renew_id='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.renew_id' :class='!checkFieldEditable(item.type,"renew_id")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"renew_id") || isTechUser' :title='item.renew_id' :rules='formRules.contentLenRule100')
                        template(v-slot:item.instance_number='{ item }')
                            v-row(no-gutters)
                                v-col(cols='12')
                                    v-text-field.v-text-field_low_z-index(v-model='item.instance_number' :class='!checkFieldEditable(item.type,"instance_number")? "blue lighten-5":""' :disabled='checkFieldEditable(item.type,"instance_number") || isTechUser || !is_staff' :title='item.instance_number' :rules='formRules.contentLenRule100')
            .action-section
                v-pagination.py-1.mb-4(v-model='page' :length='pageCount' circle :total-visible="10" )
        v-dialog(v-model='soDialog' v-if='soDialog' width='500' content-class='statusDialog' @keydown.esc='onEmitSoDialog(false)' @keydown.enter='$refs.soDialog.onConfirm()' persistent)
            soDialog(ref='soDialog' @emitSoDialog='onEmitSoDialog' :soData='soData')
        v-dialog(v-model='textDialog' v-if='textDialog' width='500' content-class='statusDialog' @keydown.esc='onEmitTextDialog(false)' @keydown.enter='onEmitTextDialog(true)' persistent)
            textDialog(@emitTextoDialog='onEmitTextDialog' :textData='textData' :textDataKey='textDataKey')

</template>
<script>
import Vue from "vue";
import Sortable from "sortablejs";
import PreferenceService from "@/services/PreferenceService";
import ProductDataService from "@/services/ProductDataService";
import ProductLineItemService from "@/services/ProductLineItemService";
import OpportunityDataService from "@/services/OpportunityDataService";
import ContactDataService from "@/services/ContactDataService";
import messageDialog from '@/components/Dialog/messageDialog';
import i18n from '/common/plugins/vue-i18n.js';
import soDialog from '@/components/Dialog/Product/soDialog';
import textDialog from '@/components/Dialog/Product/textDialog';
import productLineItemLists from "@/array/the_productLineItemLists";

export default Vue.extend({ 
    props: {
        target_class:{
            type: String,
            require: true,
        },
        target_id:{
            type: String,
            require: true,
        },
        tax_option:{
            type: String,
            require: true,
        },
        baseHeaders:{
            type: Array,
            require: true,
        },
        isAddProduct:{
            type: Boolean,
            require: false,
        },
        can_edit:{
            type: Boolean,
            require: true,
        },
        user_list:{
            type: Array,
            require: true,
        },
        technician_list:{
            type: Array,
            require: true,
        },
        product_type_list:{
            type: Array,
            require: true,
        },
        service_or_license:{
            type: String,
            require: true,
        },
        end_account_list:{
            type: Array,
            require: true,
        },
        order_org_data:{
            type: Object,
            require: false,
        },
    },
    components: {
        messageDialog,
        soDialog,
        textDialog,
    },
    data() {
        return {
            selectedRows:[],
            message:'',
            messageDialog:false,
            page: 1,
            pageCount: 0,
            itemPerPage: 10,
            headers: [
                {  text: i18n.t('PRODUCTSETTING.PRODUCT'), value: 'product_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.TYPE'), value: 'product_type', align: 'center',},
                {  text: i18n.t('PRODUCTSETTING.PRICR'), value: 'price', align: 'center', },
                {  text: i18n.t('PRODUCTLINEITEM.QUANTITY_H'), value: 'quantity', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.INVOIVE_MONTH'), value: 'invoice_month', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.INVOICE_PRICE'), value: 'invoice_price', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.ESTMATED_G_P'), value: 'estimated_gross_profit', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.POFIT'), value: 'profit', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.TECH_COST'), value: 'technician_cost', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.TECH'), value: 'technician_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.PROJECT_CODE'), value: 'project_code', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.OTHER_CHARGES')+" (%)", value: 'other_charges', align: 'center'},
                {  text: i18n.t('PRODUCTLINEITEM.DISCOUNT_H')+" (%)", value: 'discount', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.TAX_H')+" (%)", value: 'tax', align: 'center',},
                {  text: i18n.t('PRODUCTLINEITEM.AMOUNT_H'), value: 'net_amount', align: 'center',},
                {  text: i18n.t('GENERAL.ACTIVE'), value: 'active', align: 'center', sortable: false, width: "60",},
            ],
            service_headers: [
                {  text: i18n.t('PRODUCTSETTING.PRODUCT'), value: 'product_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.ORDER_STATUS'), value: 'order_status', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.OWNER'), value: 'owner_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.REMAIN_DAYS'), value: 'remain_days', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.ORGANIZATION'), value: 'organization', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.END_ACCOUNT_NAME'), value: 'end_account_name', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTACT'), value: 'contact_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 2", value: 'contact_second_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 3", value: 'contact_third_name', align: 'center',},
                {  text: "Type", value: 'product_type', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.PROJECT_NAME'), value: 'project_name', align: 'center',},
                {  text: "SLA", value: 'sla', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.SERVICE_CONTENT'), value: 'service_content', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.START_DATE'), value: 'start_date', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.END_DATE'), value: 'end_date', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.SERVICE_PLAN'), value: 'service_plan', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.SERVICE_VENDOR'), value: 'service_vendor', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.STATUS'), value: 'status', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.NOTE'), value: 'note', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.SO'), value: 'sales_order', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.SO_LINE'), value: 'sales_order_line', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.PO'), value: 'purchase_order', align: 'center',},
                {  text: 'Project Code', value: 'project_code', align: 'center',},

                // {  text: i18n.t('PRODUCTSETTING.PRODUCT'), value: 'product_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.TYPE'), value: 'product_type', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.OWNER'), value: 'owner_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.ORGANIZATION'), value: 'organization', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.ORDER_STATUS'), value: 'order_status', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.REMAIN_DAYS'), value: 'remain_days', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.END_ACCOUNT_NAME'), value: 'end_account_name', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTACT'), value: 'contact_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 2", value: 'contact_second_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 3", value: 'contact_third_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.PROJECT_NAME'), value: 'project_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.SLA'), value: 'sla', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.SERVICE_CONTENT'), value: 'service_content', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.SERVICE_PLAN'), value: 'service_plan', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.SERVICE_VENDOR'), value: 'service_vendor', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.START_DATE'), value: 'start_date', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.END_DATE'), value: 'end_date', align: 'center',},
                // // {  text: i18n.t('SERVICE_PRODUCT_LINE.ORDER_NUMBER'), value: 'order_number', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.STATUS'), value: 'status', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.NOTE'), value: 'note', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.SO'), value: 'sales_order', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.SO_LINE'), value: 'sales_order_line', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.PO'), value: 'purchase_order', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.PROJECT_CODE'), value: 'project_code', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.RELATED_OPPTY'), value: 'related_opportunity', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.TECH'), value: 'technician_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.TECH_COST'), value: 'technician_cost', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.INVOIVE_MONTH'), value: 'invoice_month', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.INVOICE_PRICE'), value: 'invoice_price', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.ESTMATED_G_P'), value: 'estimated_gross_profit', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.POFIT'), value: 'profit', align: 'center',},
            ],
            license_headers: [
                {  text: i18n.t('LICENSE_PRODUCT_LINE.REMAIN_DAYS'), value: 'remain_days', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.ORDER_STATUS'), value: 'order_status', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.OWNER'), value: 'owner_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.ORGANIZATION'), value: 'organization', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.END_ACCOUNT_NAME'), value: 'end_account_name', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTACT'), value: 'contact_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 2", value: 'contact_second_name', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 3", value: 'contact_third_name', align: 'center',},
                {  text: "Type", value: 'product_type', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.BILLING_F'), value: 'billing_frequency', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.PRODUCTCODE'), value: 'product_code', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.PRODUCT_NAME'), value: 'product_name', align: 'center',},
                {  text: i18n.t('ORDER.QUANTITY'), value: 'quantity', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.START_DATE'), value: 'start_date', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.END_DATE'), value: 'end_date', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.ORDER_NUMBER'), value: 'order_number', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.STATUS'), value: 'status', align: 'center',},
                {  text: i18n.t('SERVICE_PRODUCT_LINE.NOTE'), value: 'note', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.SO'), value: 'sales_order', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.SO_LINE'), value: 'sales_order_line', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.PO'), value: 'purchase_order', align: 'center',},
                {  text: 'Project Code', value: 'project_code', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.SUBCRIPTION_NUMBER'), value: 'subcription_number', align: 'center',},
                {  text: 'Agreement Number', value: 'agreement_number', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTRACT_LEVEL'), value: 'contract_level', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTRACT_NUMBER'), value: 'contract_number', align: 'center',},
                {  text: "PA Number", value: 'pa_number', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.OKS_NUMBER'), value: 'oks_number', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.CSI_NUMBER'), value: 'csi_number', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.RENEW_ID'), value: 'renew_id', align: 'center',},
                {  text: i18n.t('LICENSE_PRODUCT_LINE.INSTANCE_NUMBER'), value: 'instance_number', align: 'center',},

                // {  text: i18n.t('PRODUCTSETTING.PRODUCT'), value: 'product_name', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.TYPE'), value: 'product_type', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.PRODUCTCODE'), value: 'product_code', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.OWNER'), value: 'owner_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.ORGANIZATION'), value: 'organization', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.ORDER_STATUS'), value: 'order_status', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.REMAIN_DAYS'), value: 'remain_days', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.END_ACCOUNT_NAME'), value: 'end_account_name', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTACT'), value: 'contact_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 2", value: 'contact_second_name', align: 'center',},
                // {  text: i18n.t('SERVICE_PRODUCT_LINE.CONTACT')+" 3", value: 'contact_third_name', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.BILLING_F'), value: 'billing_frequency', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.START_DATE'), value: 'start_date', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.END_DATE'), value: 'end_date', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.ORDER_NUMBER'), value: 'order_number', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.STATUS'), value: 'status', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.NOTE'), value: 'note', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.SO'), value: 'sales_order', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.SO_LINE'), value: 'sales_order_line', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.PO'), value: 'purchase_order', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.PROJECT_CODE'), value: 'project_code', align: 'center',},
                // // {  text: i18n.t('LICENSE_PRODUCT_LINE.RELATED_OPPTY'), value: 'related_opportunity', align: 'center',},
                // // {  text: i18n.t('LICENSE_PRODUCT_LINE.TECH'), value: 'technician_name', align: 'center',},
                // // {  text: i18n.t('LICENSE_PRODUCT_LINE.TECH_COST'), value: 'technician_cost', align: 'center',},
                // // {  text: i18n.t('LICENSE_PRODUCT_LINE.INVOIVE_MONTH'), value: 'invoice_month', align: 'center',},
                // // {  text: i18n.t('LICENSE_PRODUCT_LINE.INVOICE_PRICE'), value: 'invoice_price', align: 'center',},
                // // {  text: i18n.t('LICENSE_PRODUCT_LINE.ESTMATED_G_P'), value: 'estimated_gross_profit', align: 'center',},
                // // {  text: i18n.t('LICENSE_PRODUCT_LINE.POFIT'), value: 'profit', align: 'center',},
                // {  text: i18n.t('PRODUCTLINEITEM.QUANTITY_H'), value: 'quantity', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.SUBCRIPTION_NUMBER'), value: 'subcription_number', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.AGREEMENT_NUMBER'), value: 'agreement_number', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTRACT_LEVEL'), value: 'contract_level', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.CONTRACT_NUMBER'), value: 'contract_number', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.PA_NUMBER'), value: 'pa_number', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.OKS_NUMBER'), value: 'oks_number', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.CSI_NUMBER'), value: 'csi_number', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.RENEW_ID'), value: 'renew_id', align: 'center',},
                // {  text: i18n.t('LICENSE_PRODUCT_LINE.INSTANCE_NUMBER'), value: 'instance_number', align: 'center',},
            ],
            formRules: {
                requireRule: [
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                ],
                requireAmountRule: [
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^\d{1,17}(\.\d{0,2})?$|(^$)|null/.test(v) && !!v || i18n.t('RULE.RULE_R_NUM_2')
                ],
                nameRules: [
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                ],
                numberRule: [(v) => /^\d+(\.\d+)?$/.test(v) || i18n.t('RULE.RULE_R_NUM_5')],
                negativeNumberRule: [(v) => /^-?\d+(\.\d+)?$/.test(v) || i18n.t('RULE.RULE_R_NUM_7')],
                fromToRule: [
                    (v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v)  || i18n.t('RULE.RULE_DATE')
                ],
                contentLenRule250: [(v) => /^.{0,250}$/.test(v) || i18n.t('RULE.RULE_250')],
                contentLenRule200: [(v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')],
                contentLenRule100: [(v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100')],
                contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
            },
            loading_data: true,
            productSearchList: [],
            productsData: [],
            productList: [],
            productList_origin: [],
            oppty_amount_type: 'system_compute',
            oppty_amount: null,
            combo_product: null,
            showCombobox: [],
            isNew: [],
            comboBoxLoading: false,
            confirm_loading: false,
            search: null,
            total: null,
            update_data: {
                "type":this.service_or_license,
                "target_class":this.target_class,
                "target_id":this.target_id,
                "create": [],
                "delete": [],
                "edit": []
            },
            count: -1,
            sales_tax: null,
            tax_data: null,
            menu_from: false,
            menu_to: false,
            table_style:"",
            clickTab:false,
            total_days:0,

            soDialog:false,
            textDialog:false,
            order_status_list:[],
            contact_list:[],
            oppty_list:[],
            sla_list:[],
            service_plan_list:[],
            service_vendor_list:[],
            status_list:[],
            invoice_month_list:[],
            productLineItemLists:productLineItemLists,
            contactSearch: null,
            contact_loading: false,
            opptySearch:null,
            oppty_loading: false,
            soData:null,
            textData:null,
            textDataKey:null,
            originalLineData:null,
            isTechUser:false,
            is_staff: false,
            soUrl:"",
            poUrl:"",
            wiAdvanceBaseHeaders:'',
            order_data:'',
        }
    },
    async created(){
        this.checkTechUser();
        await PreferenceService.getCUSTOM_DATA().then((response)=>{
            let bc_info = response.data.bc_info;
            this.soUrl = `https://businesscentral.dynamics.com/${bc_info.tenantid}/${bc_info.environmentName}/?company=${bc_info.companyName}&page=42&filter='No.' IS `
            this.poUrl = `https://businesscentral.dynamics.com/${bc_info.tenantid}/${bc_info.environmentName}/?company=${bc_info.companyName}&page=50&filter='No.' IS `
        });
        this.loading_data = true;
        this.table_style = "padding-bottom: 45px;min-width: 4000px;max-width: 760px; "
        await this.getHeaders();
        await this.getAllList();
        if(this.service_or_license == "service"){
            await ProductLineItemService.serviceList(this.target_class,this.target_id)
            .then((response) => {
                this.refineData(response);
            })
            .then(()=>{
                this.getisNewData();
            })
            .then(()=>{
                this.toggleTaxType();
            })
            .finally(()=>{
                this.loading_data = false;
            })
        }else if(this.service_or_license == "license"){
            await ProductLineItemService.licenseList(this.target_class,this.target_id)
            .then((response) => {
                this.refineData(response);
            })
            .then(()=>{
                this.getisNewData();
            })
            .then(()=>{
                this.toggleTaxType();
            })
            .finally(()=>{
                this.loading_data = false;
            })
        }
    },
    methods: {
        checkTechUser(){
            let user = JSON.parse(window.localStorage.getItem('user'));
            if(user){
                this.isTechUser = JSON.parse(window.localStorage.getItem('user'))['is_tech_user'] || this.$isViewOnly.isViewOnly;
                this.is_staff = JSON.parse(window.localStorage.getItem('user'))['is_staff'];
            }
            if(!this.isTechUser && !this.is_staff && this.target_class == "oppty"){
                this.is_staff = true;
            }
        },
        editText(item,key){
            if(this.isTechUser || !this.is_staff) return;
            this.textData = item;
            this.textDataKey = key;
            this.originalLineData = JSON.parse(JSON.stringify(item));
            this.textDialog = true;
        },
        async onEmitTextDialog(val){
            if(!val){
                let index = this.productList.indexOf(this.textData);
                this.$set(this.productList,index,this.originalLineData);
            }
            this.textDialog = false;
        },
        soOpen(soNumber){
            if(soNumber && soNumber.length>0){
                window.open(this.soUrl+soNumber);
            }
        },
        poOpen(poNumber){
            if(poNumber && poNumber.length>0){
                window.open(this.poUrl+poNumber);
            }
        },
        editSo(item){
            if(this.isTechUser) return;
            this.soData = item;
            this.originalLineData = JSON.parse(JSON.stringify(item));
            this.soDialog = true;
        },
        async onEmitSoDialog(val){
            if(!val){
                let index = this.productList.indexOf(this.soData);
                this.$set(this.productList,index,this.originalLineData);
            }
            this.soDialog = false;
        },
        contactFocus(index){
            this.$refs[`contact_${index}`].isFocused=true;
        },
        contactFocus2(index){
            this.$refs[`contact_sec_${index}`].isFocused=true;
        },
        contactFocus3(index){
            this.$refs[`contact_rd_${index}`].isFocused=true;
        },
        contactBlur(item){
            this.contact_list = [];
            this.contactSearch = null;
            if(item.contact){
                item.contact_name = item.contact.full_name;
            }
            if(item.contact_second){
                item.contact_second_name = item.contact_second.full_name;
            }
            if(item.contact_third){
                item.contact_third_name = item.contact_third.full_name;
            }
        },

        opptyFocus(index){
            this.$refs[`oppty_${index}`].isFocused=true;
        },
        opptyBlur(item){
            this.oppty_list = [];
            this.opptySearch = null;
            if(item.related_opportunity){
                item.related_opportunity_name = item.related_opportunity.name;
            }else{
                item.related_opportunity_name = null;
            }
        },
        async getHeaders(){
            if(this.target_class != "oppty"){
                this.wiAdvanceBaseHeaders = this.baseHeaders.filter(e=>e.value=='active')
                this.headers = this.service_or_license == 'service'?  this.service_headers.concat(this.wiAdvanceBaseHeaders):this.license_headers.concat(this.wiAdvanceBaseHeaders);
            }
            this.headers.forEach(el=> {
                if(el.value == 'active'){
                    el.width =  '120px'
                }else{
                    el.width =  '200px'
                }
            });
        },
        getContractLevelList(type){
            let elem = this.product_type_list.filter(el=>el.id == type)[0];
            if(elem){
                if(elem.name.includes("EA")){
                    return [{key:'A',name:"A"},{key:'B',name:"B"},{key:'C',name:"C"},{key:'D',name:"D"}]
                }
                if(elem.name.includes("MPSA")){
                    return [{key:'E',name:"系統Systems(A/B/C.D)"},{key:'F',name:"伺服器Servers(A/B/C.D)"},{key:'G',name:"應用程式Applications(A/B/C.D)"}]
                }
            }
        },
        checkFieldEditable(type,field_name){
            let elem = this.product_type_list.filter(el=>el.id == type)[0];
            if(elem){
                if(elem.name.includes("CSP")){
                    if(field_name=="subcription_number"){
                        return false
                    }
                }else if(elem.name.includes("EA")){
                    if(field_name=="agreement_number" || field_name=="contract_level"){
                        return false
                    }
                }else if(elem.name.includes("MPSA")){
                    if(field_name=="contract_number" || field_name=="contract_level" || field_name=="pa_number"){
                        return false
                    }
                }else if(elem.name.includes("Oracle Renew")){
                    if(field_name=="oks_number"){
                        return false
                    }
                }else if(elem.name.includes("Oracle New")){
                    if(field_name=="csi_number"){
                        return false
                    }
                }else if(elem.name.includes("OCI Cloud Service")){
                    if(field_name=="renew_id"){
                        return false
                    }
                }else if(elem.name.includes("Linux Support")){
                    if(field_name=="instance_number"){
                        return false
                    }
                }
            }
            return true
        },
        async getAllList(){
            this.order_status_list = this.productLineItemLists['order_status_list'];
            this.billing_frequency_list = this.productLineItemLists['billing_frequency_list'];
            this.sla_list = this.productLineItemLists['sla_list'];
            this.service_plan_list = this.productLineItemLists['wiadvance_service_plan_list'];
            this.service_vendor_list = this.productLineItemLists['wiadvance_service_vendor_list'];
            this.status_list = this.productLineItemLists['status_list'];
            this.invoice_month_list = this.productLineItemLists['invoice_month_list'];
        },
        nameTabBlur(index,item){
            this.clickTab = true;
            const setName = new Promise((resolve) => {
                if(this.combo_product!= null){
                    item.product_name = this.combo_product.name;
                    item.product = this.combo_product.id;
                    item.enable_trq = this.combo_product.enable_trq;
                    item.price = this.combo_product.unit_price != null ? this.combo_product.unit_price : 0;
                    item.tax = this.combo_product.tax != null ? this.combo_product.tax : 0;
                    item.unit = this.combo_product.unit != null ? this.combo_product.unit : null;
                    item.other_charges = this.combo_product.other_charges != null ? this.combo_product.other_charges : 0;
                    item.start_date = this.combo_product.start_date != null ? this.combo_product.start_date : null;
                    item.end_date = this.combo_product.end_date != null ? this.combo_product.end_date : null;
                    if(this.service_or_license == "license"){
                        item.product_code = this.combo_product.product_code != null ? this.combo_product.product_code : null;
                    }

                    item.sequence = index;
                    this.isNew[index] = false;
                }
                else if(this.combo_product == null && this.search != null){
                    if(this.search.trim() != ''){
                        item.enable_trq = false;
                        item.price = 0;
                        item.tax = 0;
                        item.unit = null;
                        item.other_charges = 0;
                        item.start_date = null;
                        item.end_date = null;
                        item.product = {name: this.search}
                        item.product_name = this.search;
                        this.isNew[index] = true;
                    }
                }
                resolve();
            })

            setName
            .then(()=>{
                this.combo_product = null;
                this.productSearchList = [];
                this.$set(this.showCombobox, (index - 1), false);
            })
            this.toggleFromTo();
            setTimeout(()=>{
                this.$refs["price_"+index].focus();
            },0)
        },
        minDate(date){
            let current_date = new Date(date).getTime();
            let min = current_date + 1000*60*60*24;
            return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)
        },
        toDateCheck(item){
            if(item.start_date){
                let FD = new Date(item.start_date).getTime();
                let TD = new Date(item.end_date).getTime();
                if(item.end_date && (TD-FD)<=0){
                    item.end_date = this.minDate(item.start_date);
                }
            }
        },
        validate(){
            return this.$refs.productLineItem_form.validate();
        },
        refineData(response){
            response.data.product_list.sort(function(a, b){
                return a.sequence - b.sequence
            });
            this.productList = response.data.product_list;
            this.productsData = response.data;
            for(let i of this.productList){
                if(i.sales_order == null){
                    i.sales_order =  {name:"",url:""};
                }
            }
            this.productList_origin = JSON.parse(JSON.stringify(response.data.product_list));
            if (this.target_class == 'order') this.setOrganization(this.order_org_data);
            this.tax_option == "TAX_EXCLUSIVE" ? this.total = this.getFormatAmount(this.productsData.Total.TAX_EXCLUSIVE) : this.total = this.getFormatAmount(this.productsData.Total.OTHER);
            this.sales_tax = this.productsData.sales_tax;

            this.productsData.tax_data['TAX_EXCLUSIVE'].sort(function(a, b){
                return b.tax - a.tax
            });
            this.productsData.tax_data['TAX_INCLUSIVE'].sort(function(a, b){
                return b.tax - a.tax
            });
            this.tax_data = this.productsData.tax_data;
            this.productsData.discount_data = this.productsData.discount_data.sort(function(a, b){
                return b.discount - a.discount
            });
        },
        async onCreate(){
            this.loading_data = true;
            let lineItem = this.service_or_license == 'service'? this.defualtServiceData() : this.defualtLicenseData();
            if(this.target_class == 'oppty'){
                lineItem.opportunity = this.target_id;
                lineItem.organization = null;
            }else{
                lineItem.order = this.target_id;
            }
            this.count--;
            await this.productList.unshift(lineItem);
            await this.isNew.unshift(false);
            this.$emit('emitAddProduct');
            await this.getComboboxData();
            this.loading_data = false;
        },
        defualtServiceData(){
            return {
                id: this.count,
                contact : null,
                contact_second : null,
                contact_third : null,
                discount : 0,
                end_account : null,
                end_account_name : null,
                end_date : null,
                estimated_gross_profit : 0,
                invoice_month : null,
                invoice_price : 0,
                net_amount : 0,
                note : null,
                opportunity : null,
                order : null,
                order_number : null,
                order_status : null,
                other_charges : 0,
                owner : null,
                owner_name : null,
                organization : this.order_org_data ? this.order_org_data.name : this.order_org_data,
                price : 0,
                product : null,
                product_name : null,
                product_type_name : null,
                profit : 0,
                project_code : null,
                project_name : null,
                purchase_order : null,
                quantity : 1,
                related_opportunity : null,
                sales_order : {name:"",url:""},
                sales_order_line : null,
                service_content : null,
                service_plan : null,
                service_vendor : null,
                sla : null,
                start_date : null,
                status : null,
                tax : 0,
                technician : null,
                technician_cost : 0,
                type : null,
                sequence:0
            }
        },
        defualtLicenseData(){
            return {
                id: this.count,
                contact : null,
                contact_second : null,
                contact_third : null,
                discount : 0,
                end_account : null,
                end_account_name : null,
                end_date : null,
                estimated_gross_profit : 0,
                invoice_month : null,
                invoice_price : 0,
                net_amount : 0,
                note : null,
                opportunity : null,
                order : null,
                order_number : null,
                order_status : null,
                other_charges : 0,
                owner : null,
                owner_name : null,
                organization : this.order_org_data ? this.order_org_data.name : this.order_org_data,
                price : 0,
                product : null,
                product_name : null,
                product_type_name : null,
                profit : 0,
                project_code : null,
                project_name : null,
                purchase_order : null,
                quantity : 0,
                related_opportunity : null,
                sales_order : {name:"",url:""},
                sales_order_line : null,
                billing_frequency : null,
                start_date : null,
                status : null,
                tax : 0,
                technician : null,
                technician_cost : 0,
                type : null,
                product_code : null,
                sequence:0,
                subcription_number:null,
                agreement_number:null,
                contract_level:null,
                contract_number:null,
                pa_number:null,
                oks_number:null,
                csi_number:null,
                renew_id:null,
                instance_number:null,
            }
        },
        async onCancel(){
            this.loading_data = true;
            this.productList = await JSON.parse(JSON.stringify(this.productList_origin));
            await this.getisNewData();
            this.refine_updateData();
            await this.toggleFromTo();
            await (this.loading_data = false);
        },
        async onConfirm(){
            if(this.validate()){
                await this.getCreateData();
                await this.getEditData();
                await ProductLineItemService.change(this.update_data)
                    .then((response) => {
                        this.refineData(response);
                    })
                    .then(()=>{
                        this.getisNewData();
                        this.refine_updateData();
                    })
                    .then(()=>{
                        this.toggleTaxType();
                    })
                    .finally(()=>{
                        this.loading_data = false;
                    })
                return this.total;
            }
        },
        async onDelete(item){
            if(item.id > 0){
                this.update_data.delete.push(item.id);
            }
            this.productList = await this.productList.filter(e => e != item);
            await this.getisNewData();
            await this.getComboboxData();
            await this.toggleFromTo();
        },
        setOrganization(orderPropData){
            let org_name = orderPropData?.name;
            for(let i in this.productList){
                this.productList[i].organization = org_name;
            }
        },
        getCreateData(){
            this.update_data.create = JSON.parse(JSON.stringify(this.productList.filter(e => e.id < 0)));
            for(let i in this.update_data.create){
                this.update_data.create[i].id = null;
                delete this.update_data.create[i].product_name;
            }
        },
        getEditData(){
            this.update_data.edit = JSON.parse(JSON.stringify(this.productList.filter(e => e.id > 0)));
        },
        getComboboxData(){
            this.showCombobox = [];
            for(let i = 0; i < this.productList.length; i++){
                this.showCombobox.push(false);
                this.$set(this.productList[i], 'sequence', i);
            }
        },
        async getisNewData(){
            this.isNew = [];
            for(let i = 0; i < this.productList.length; i++){
                if(typeof(this.productList[i].product) == 'object' && this.productList[i].product != null){
                    this.isNew.push(true);
                }
                else{
                    this.isNew.push(false);
                }
            }
        },
        refine_updateData(){
            this.update_data = {
                "type":this.service_or_license,
                "target_class":this.target_class,
                "target_id":this.target_id,
                "create": [],
                "delete": [],
                "edit": []
            }
        },
        async nameBlur(item){
            await this.$set(this.showCombobox, (item.sequence - 1), true);
            const combobox = await this.$refs.combobox;
            await combobox.focus();
        },
        nameComboBlur(item){
            if(this.clickTab){
                this.clickTab = false;
                return;
            }
            const setName = new Promise((resolve) => {
                if(this.combo_product!= null){
                    item.product_name = this.combo_product.name;
                    item.product = this.combo_product.id;
                    item.type = this.combo_product.category.id;
                    item.product_type_name = this.combo_product.category.name;
                    item.enable_trq = this.combo_product.enable_trq;
                    item.price = this.combo_product.unit_price != null ? this.combo_product.unit_price : 0;
                    item.tax = this.combo_product.tax != null ? this.combo_product.tax : 0;
                    item.unit = this.combo_product.unit != null ? this.combo_product.unit : null;
                    item.other_charges = this.combo_product.other_charges != null ? this.combo_product.other_charges : 0;
                    item.start_date = this.combo_product.start_date != null ? this.combo_product.start_date : null;
                    item.end_date = this.combo_product.end_date != null ? this.combo_product.end_date : null;
                    if(this.service_or_license == "license"){
                        item.product_code = this.combo_product.product_code != null ? this.combo_product.product_code : null;
                    }

                    this.isNew[item.sequence] = false;
                }
                else if(this.combo_product == null && this.search != null){
                    if(this.search.trim() != ''){
                        item.product = {name: this.search}
                        item.product_name = this.search;
                        this.isNew[item.sequence] = true;
                    }
                }
                resolve();
            })

            setName
            .then(()=>{
                this.combo_product = null;
                this.productSearchList = [];
                this.$set(this.showCombobox, (item.sequence - 1), false);
            })
            this.toggleFromTo();
        },
        get_sequence(event){
            const getNewSeq = new Promise((resolve) => {
                this.showCombobox = [];
                const movedItem = this.productList.splice(event.oldIndex,1)[0];
                this.productList.splice(event.newIndex, 0, movedItem);
                resolve();
            })
            
            getNewSeq
            .then(()=>{
                for(let i in this.productList){
                    this.showCombobox.push(false);
                    this.productList[i].sequence = Number(i);
                }
            })
            .then(()=>{
                this.getisNewData();
            })
        },
        DateDiff(date1, date2){
            let strDate, oDate1, oDate2, result
            strDate = date1.split("/");
            oDate1 = new Date(strDate[1] + '/' + strDate[2] + '/' + strDate[0]);
            strDate = date2.split("/");
            oDate2 = new Date(strDate[1] + '/' + strDate[2] + '/' + strDate[0]);
            result = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); 
            return result;
        },
        calcPriceAndQuantity(item){
            let day = 0;
            let amount = 0;
            if(this.service_or_license=='service') item.quantity = 1;
            if(item.enable_trq && item.start_date && item.end_date){
                day = this.DateDiff(item.start_date,item.end_date);
            }
            if(item.enable_trq && day != 0){
                amount = (Number(item.price) * Number(day) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }else{
                amount = (Number(item.price) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }
            item.net_amount = Number(amount).toFixed(2);
        },
        calcDiscount(item){
            let day = 0;
            let amount = 0;
            if(this.service_or_license=='service') item.quantity = 1;
            if(item.enable_trq && item.start_date && item.end_date){
                day = this.DateDiff(item.start_date,item.end_date);
            }
            if(item.enable_trq && day != 0){
                amount = (Number(item.price) * Number(day) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }else{
                amount = (Number(item.price) * Number(item.quantity)) * ((100 + Number(item.other_charges))/100) * ((100 - Number(item.discount))/100);
            }
            item.net_amount = Number(amount).toFixed(2);
        },
        calcAmount(item){
            let day = 0;
            let discount = 0;
            if(this.service_or_license=='service') item.quantity = 1;
            if(item.enable_trq && item.start_date && item.end_date){
                day = this.DateDiff(item.start_date,item.end_date);
            }
            if(item.enable_trq && day != 0){
                discount = (1 - (Number(item.net_amount) / (Number(item.price) * Number(item.quantity) * Number(day) * ((100 + Number(item.other_charges))/100)))) * 100;
            }else{
                discount = (1 - (Number(item.net_amount) / (Number(item.price) * Number(item.quantity) * ((100 + Number(item.other_charges))/100)))) * 100;
            }
            item.discount = Number(discount).toFixed(2);
        },
        toggleTaxType(){
            if(this.tax_option == 'TAX_EXCLUSIVE'){
                let taxHeader = this.headers.find(e => e.value == 'tax');
                const newTaxHeader= {  
                    text: i18n.t('PRODUCTLINEITEM.TAX') + ' (%)', 
                    value: 'tax',
                    align: 'center',
                }

                if(taxHeader == undefined){
                    this.headers.splice(-2, 0, newTaxHeader)
                }
                this.total = this.getFormatAmount(this.productsData.Total.TAX_EXCLUSIVE);
            }
            else if(this.tax_option == 'NO_TAX'){
                this.headers = this.headers.filter(e => e.value != 'tax');
                this.total = this.getFormatAmount(this.productsData.Total.OTHER)
            }
        },
        toggleFromTo(){
            this.toggleTaxType()
        },
        checkWiadvanceRequiredField(){
            if(this.productList.length == 0){
                return null
            }
            for(let el of this.productList){
                if(!el.type || !el.invoice_month || !el.net_amount || !el.estimated_gross_profit || !el.profit){
                    return false
                }
            }
            return true
        }
    },
    directives: {
        sortableDataTable: {
            bind(el, binding, vnode) {
                const options = {
                animation: 150,
                    onUpdate: function (event) {
                        vnode.child.$emit("sorted", event);
                    },
                };
                Sortable.create(el.getElementsByTagName("tbody")[0], options);
            },
        },
    },
    watch:{
        page(){
            this.loading_data = true;
            setTimeout(()=>{
                this.loading_data = false;
            },200)
        },
        baseHeaders(){
            this.getHeaders();
        },
        isAddProduct(val){
            if(val){
                this.onCreate();
            }
        },
        async search(val) {
            var re = /^(?!\s*$).+/;
            var check = re.test(val)
            if(check && val != null){
                let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                if(!this.comboBoxLoading && charNum>=1 && charNum==val.length ){
                    this.comboBoxLoading = true;
                    await ProductDataService.list(1, val, 100, [],this.service_or_license)
                    .then((response)=>{
                        this.productSearchList = response.data.list;
                    })
                    .finally(() => {
                        this.comboBoxLoading = false;
                    });
                }
                else {
                    this.comboBoxLoading = false;                        
                }
            }
        },
        contactSearch(val) {
            // check user input is en / zh / num
            let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            let isEnZhNum = false;
            pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            // check user input data type
            let isString; 
            typeof(val) == 'string' ? isString = true : isString = false;
            let array = [];

            // search
            if(isEnZhNum && !!val && isString)
            {
                this.contact_loading = true;
                ContactDataService.getAll(1,val,100,[])
                .then(response => {
                    response.data.list.forEach(item => {
                        item.searchStr = item.full_name 
                                        +item.full_name_en
                                        +item.full_name_en.toLowerCase()
                                        +item.full_name_en.toUpperCase()
                                        +item.full_name_zh;
                        if(item.searchStr.includes(val))
                        {
                            if(item.organization_set.length > 0)
                            {
                                item.org_name = "[ "+item.organization_set[0].name +" ]";
                            }
                            array.push(item);
                        }
                    })
                    this.contact_list = array;
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                .finally(()=>{
                    this.contact_loading = false;
                })
            }
            else{
                this.contact_list = [];
            }
        },
        opptySearch(val) {
            // check user input is en / zh / num
            let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            let isEnZhNum = false;
            pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            // check user input data type
            let isString; 
            typeof(val) == 'string' ? isString = true : isString = false;

            // search
            if(isEnZhNum && !!val && isString)
            {
                this.oppty_loading = true;
                OpportunityDataService.list('list', 'all', 0, 1, val, 50, [],'')
                .then(response => {
                    this.oppty_list = response.data.list;
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                .finally(()=>{
                    this.oppty_loading = false;
                })
            }
            else{
                this.oppty_list = [];
            }
        },
    }
})
</script>

<style lang="scss">
.text-black input{
  color: rgba(0, 0, 0, 0.87) !important;
}
.text-primary input{
  color: #007BFF !important;
  cursor: pointer;
}
</style>