import i18n from '/common/plugins/vue-i18n.js' 

let lang = window.localStorage.getItem('language');
if(!lang)	
{
    lang = "zh_TW"				
}
i18n.locale = lang

function isWiadvance(){
	let tanant = window.localStorage.getItem('tenant');
	return tanant == "wiadvance" || tanant == "demo" ? true : false ;
}

const pageList = [
    { key:'Contact', name: i18n.t('CONTACT.TITLE')},
	{ key:'Organization', name: i18n.t('COMPANY.TITLE')},
	{ key:'Case', name: i18n.t('CASE.TITLE')},
	{ key:'Opportunity',name: i18n.t('DEAL.TITLE')},
	{ key:'KnowledgeArticle',name: i18n.t('KNOWLEDGE_ARTICLE.TITLE')},
	{ key:'Activity',name: i18n.t('ACTIVITY.TITLE')},
	{ key:'Product',name: i18n.t('PRODUCTSETTING.PRODUCT')},
	{ key:'Order',name: i18n.t(`ORDER.${isWiadvance()  ? 'WIADVANCE_TITLE' : 'TITLE'}`) ,type:'text'},
];
if(isWiadvance()){
	pageList.push({ key:'Order_License',name: i18n.t(`ORDER_TYPE.LICENSE_PRODUCT`)});
	pageList.push({ key:'Order_Service',name: i18n.t(`ORDER_TYPE.SERVICE_PRODUCT`)});
}

export default pageList;