<template lang="pug">
    div#processAttributeSet(class="align-center")
        v-row(v-if="type=='folder' || type=='image' || type=='cloud'"  no-gutters class="align-center" :class="type=='cloud'? 'mt-4': ''")
            label.mr-2(class='d-flex align-center')
                p.mb-2(:class="!can_image_process?'t-gray font-weight-bold':'font-weight-bold'") {{$t('GPT_KNOWLEDGE.IMAGE_SELECTION')}} :
            v-tooltip(top)
                template(v-slot:activator='{ on, attrs }')
                    v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                        v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.DESCRIBE_IMG')` :disabled='!can_image_analysis' v-model='describe_image' @change="handleCheckboxChange()")
                span.mt-1 {{$t('GPT_UPLOADIMG.TIP_1')}}
            v-tooltip(top)
                template(v-slot:activator='{ on, attrs }') 
                    v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                        v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.IMG_SEARCH')` :disabled='!can_image_search' v-model='search_image' @change="handleCheckboxChange()")
                span.mt-1 {{$t('GPT_UPLOADIMG.TIP_3')}}
            v-tooltip(top)
                template(v-slot:activator='{ on, attrs }') 
                    v-col.pr-3(cols='auto' v-bind='attrs' v-on='on')
                        v-row(no-gutters)
                            v-col.mr-2(cols='auto')
                                v-checkbox.mt-0.no-border(no-resize :label=`$t('GPT_UPLOADIMG.CUSTOMER_ATTRIBUTE')` :disabled='!can_image_analysis' v-model='customer_arrtibute' @change="handleCheckboxChange()")
                            v-col(cols='6' class='d-flex' v-if='can_image_analysis')
                                v-select.mt-0.pt-0(v-if='customer_arrtibute' v-model='currentAttributeId' :items='customerAttributeList' item-value='id' item-text='name' :title='getItemNameFromListById(customerAttributeList,currentAttributeId)' style='max-width:150px;')
                                v-btn.pl-1(class='justify-start' v-if="customer_arrtibute && customerAttributeList.length > 0 " size="25" icon @click='showImageDataTable()')
                                    v-icon mdi-grid 
                span.mt-1 {{$t('GPT_UPLOADIMG.TIP_2')}}
        v-row(v-if="type=='folder' || type=='file' || type=='cloud'" no-gutters class="align-center")
            label.mr-2(class='d-flex align-center')
                p.mb-2(:class="!can_intelligent_analysis?'t-gray font-weight-bold':'font-weight-bold'") {{$t('GPT_IDP.DOCUMENT_ANALYSIS')}} :
            v-tooltip(bottom)
                template(v-slot:activator='{ on, attrs }')
                    v-col.pr-3(cols='6' v-bind='attrs' v-on='on') 
                        v-checkbox.mt-0.no-border(no-resize v-model='intelligent_document_analysis' :disabled='!can_intelligent_analysis')
                span.mt-1 {{$t('GPT_IDP.TIP_1')}}     

        v-dialog(v-model='imageDataDialog' max-width='1200' content-class='statusDialog')
            imageData-dialog(@emitImageDataDialog='onEmitImageDataDialog' :imageAttributeHeader='imageAttributeHeader' :imageAttributeTableData='imageAttributeTableData' :currentAttributeId='currentAttributeId')
</template>

<script>
import i18n from '/common/plugins/vue-i18n.js';
import KnowledgeLibraryService from "@/services/KnowledgeLibraryService";
import AiBotsService from "@/services/AiBotsService";
import imageDataDialog from "@/components/GPT_Dialog/Knowledge/imageDataTable";
export default {
    props: {
        target_id: {
            type: String,
            required: true,
        },
        type:{
            type: String,
            required: true,
        }, 
    },
    components: {
        imageDataDialog
    },
    created(){
        this.getCustomerAttributeList()
        this.getImageCheckboxesSet(this.type);
        this.get_upgpt_setting();
    
    },
    data(){
        return{
            currentAttributeId: '',
            describe_image: '',
            search_image: '',
            customer_arrtibute: '',
            intelligent_document_analysis:false,
            can_image_search:false,
            can_image_analysis:false,
            can_image_process:false,
            can_intelligent_analysis:false,
            // image data table
            imageDataDialog: false,
            imageAttributeHeader:[],
            imageAttributeTableData: [],
            imageAttributeTablePage:'',
        }
    },
    methods: {
        async get_upgpt_setting(){
            let setting = await this.upgpt_image_func_setting();
            this.can_image_search = setting['can_image_search'];
            this.can_image_analysis = setting['can_image_analysis'];
            this.can_image_process = setting['can_image_process'];
            this.can_cloud_drive = setting['can_cloud_drive'];
            this.can_intelligent_analysis = setting['can_intelligent_analysis'];
        },
        async getCustomerAttributeList(){
            await AiBotsService.field_list().then(response => 
            {
                this.customerAttributeList = response.data
                this.currentAttributeId = this.customerAttributeList[0]['id']
            })
        },
        async handleCheckboxChange(){
            let data = {
                'knowledge_lib_id':this.target_id,
                'upload_type':this.type,
                'describe_image_by_ai':this.describe_image,  
                'save_customeized_attributes':this.customer_arrtibute,  
                'image_search':this.search_image,  
            }
            await KnowledgeLibraryService.saveImagecheckboxSet(data)
        },
        getImageCheckboxesSet(current_type){
            KnowledgeLibraryService.imagecheckboxSet(this.target_id).then(response => 
            {
                if(current_type == 'image'){
                    this.describe_image = response.data.image.describe_image_by_ai
                    this.search_image = response.data.image.image_search
                    this.customer_arrtibute = response.data.image.save_customeized_attributes
                }
                if(current_type == 'cloud'){
                    this.describe_image = response.data.cloud.describe_image_by_ai
                    this.search_image = response.data.cloud.image_search
                    this.customer_arrtibute = response.data.cloud.save_customeized_attributes
                }
                if(current_type == 'folder'){
                    this.describe_image = response.data.folder.describe_image_by_ai
                    this.search_image = response.data.folder.image_search
                    this.customer_arrtibute = response.data.folder.save_customeized_attributes
                }

            })
    
        },
        showImageDataTable(){
            this.imageDataDialog = true;
            this.getImageAttributeHeader();
            this.getImageAttributeData();
        },
        async getImageAttributeHeader(){
            let data = {'currentObjectTypeId': this.currentAttributeId};
            await AiBotsService.get_image_attribute_header(data).then(response => 
                {
                    let array = [];
                    response.data.forEach(e => {
                        let item = {
                            text: e.name,
                            value: e.flexfield_column,
                            align: 'center',
                            sortable: false,
                            data_type: e.flexfield_datatype,
                        };
                        array.push(item)
                    })
                    array.sort((a,b)=> a.text.localeCompare(b.text))
                    array.sort((a,b)=> a.text.length - b.text.length)
                    let action = {
                        text: i18n.t('GENERAL.ACTIVE') ,
                        value: "action",
                        align: "center",
                        width: '150px',
                        sortable: false,
                        data_type: '',
                    };
                    array.push(action)

                    if(array.length >= 6){
                        array.forEach(e=>{
                            e.width =  '200px';
                        })
                    }
                    
                    this.imageAttributeHeader = array
                })
        },
        async getImageAttributeData(){
            let data = {'currentObjectTypeId': this.currentAttributeId}
            await AiBotsService.get_image_attribute_table(data).then(response => 
            {
                this.imageAttributeTableData = response.data;
                this.imageAttributeTableData.forEach(keyItem => {
                    Object.keys(keyItem).forEach(key => {
                        if(key.includes('flexfield_datetime')){
                            keyItem[key] = this.convertDateTime(keyItem[key]);
                        }
                    })   
                })
            })
        },
        // 日期轉型 YYYY-MM-DD
        convertDateTime(time){
            if(time == null) return '';
            const date = new Date (time);
            return date.toISOString().split('T')[0];
        },
        onEmitImageDataDialog(action){
            if(action === 'delete'){
                this.getImageAttributeData();
            }
            this.getImageAttributeData();
        }
      
    }
}
</script>